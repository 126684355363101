import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class MeibomianGlandAssesmentDetail extends Component{
	
	render(){
		return (
			<>				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Meibomian Gland Assessment</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Meibomian Gland Assessment</h2>
											<p className="nse-paragraph mb-0">Meibomian gland assessment, also known as meibography or meibomian gland imaging, is a diagnostic procedure that we use at Nakpil Eye Clinic. Meibomian glands are specialized oil glands located in the eyelids, and they play a crucial role in maintaining the health of the tear film and preventing conditions like dry eye disease. Regular meibomian gland assessment tests, also known as meibography or meibomian gland imaging, are beneficial for several reasons.</p>
											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Early Detection of Meibomian Gland Dysfunction (MGD)</h4>
											<p className="nse-paragraph mb-0">MGD is a common condition where the meibomian glands become blocked or dysfunctional, leading to poor quality meibum (the oil component of tears). Meibography can help detect MGD in its early stages, allowing for prompt intervention before symptoms worsen.</p>
											
											<h4 className="title mb-10 mt-10">Assessment of Gland Structure</h4>
											<p className="nse-paragraph mb-0">Meibography provides a detailed view of the meibomian glands' structure and can reveal issues like gland atrophy or dropout. Changes in gland structure can be indicative of MGD severity.</p>

											<h4 className="title mb-10 mt-10">Monitoring Progression</h4>
											<p className="nse-paragraph mb-0">Regular meibomian gland assessments help monitor the progression of MGD and evaluate the effectiveness of treatment over time. This is important for developing and adjusting treatment plan.</p>

											<h4 className="title mb-10 mt-10">Treatment Guidance</h4>
											<p className="nse-paragraph mb-0">Meibomian gland assessments can guide treatment decisions. Depending on the severity of MGD, treatment options may include warm compresses, lid hygiene, lubricating eye drops, prescription medications, or in-office procedures like meibomian gland expression or Intense Pulsed Light (IPL) therapy. Assessments help determine which treatment is most appropriate.</p>

											<h4 className="title mb-10 mt-10">Customized Therapy</h4>
											<p className="nse-paragraph mb-0">The findings from meibography can aid in tailoring therapy to the individual's needs. Some people may require more intensive treatment, while others may benefit from simple at-home care.</p>

											<h4 className="title mb-10 mt-10">Patient Education</h4>
											<p className="nse-paragraph mb-0">Meibography can serve as a visual tool for patient education. Patients can see the status of their meibomian glands, helping them understand the importance of compliance with treatment and lifestyle changes.</p>

											<h4 className="title mb-10 mt-10">Prevention of Complications</h4>
											<p className="nse-paragraph mb-0">Severe or untreated MGD can lead to complications like dry eye syndrome, corneal irritation, and inflammation. Early assessment and intervention can help prevent these complications.</p>

											<h4 className="title mb-10 mt-10">Research and Documentation</h4>
											<p className="nse-paragraph mb-0"> Meibography is valuable in clinical research and for documenting a patient's eye health history. It allows eye care professionals to track changes in the meibomian glands and assess the effectiveness of various treatments.</p>

											<h4 className="title mb-10 mt-10">Contact Lens Wearers</h4>
											<p className="nse-paragraph mb-0">Meibomian gland assessments are particularly important for contact lens wearers, as contact lenses can affect tear film stability. Identifying MGD in these individuals can help improve the comfort and longevity of contact lens wear.</p>

											<p className="nse-paragraph mb-0 mt-30">Overall, the benefits and purposes of regular meibomian gland assessment tests include the early detection and management of MGD, customization of treatment plans, and the prevention of complications associated with this condition. These assessments contribute to the overall health and comfort of the eyes and are especially valuable for individuals with dry eye symptoms, contact lens wearers, and those at risk of MGD due to factors like age, certain medical conditions, or medication use.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default MeibomianGlandAssesmentDetail;