import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import bg1 from '../../images/main-banner/bg1.jpg';
import doctorImg from '../../images/main-banner/doctor.png';
import ptImg1 from '../../images/shap/007-square-glasses.png';
import ptImg2 from '../../images/shap/006-contact-lens.png';
import ptImg3 from '../../images/shap/chicle-blue-2.png';
import ptImg4 from '../../images/shap/000-Blank.png';
import ptImg5 from '../../images/shap/wave-blue.png';

class aboutSection extends Component{
	render(){
		return(
			<>				
				<div className="main-banner">
					<div className="container inner-content">
						<div className="row align-items-center">
							<div className="col-lg-12 col-md-12 col-sm-12">
								<h6 className="title-ext text-white">A leading provider of eye and vision care services</h6>
								<h1 className='text-white'>Committed to providing premium quality products and services.</h1>
								{/*<Link to="/about-us" className="btn btn-secondary btn-lg">Learn More</Link>*/}
							</div>							
						</div>
					</div>
					<img className="pt-img1 animate1" src={ptImg4} alt=""/>
					<img className="pt-img2 animate2" src={ptImg4} alt=""/>
					<img className="pt-img3 animate3" src={ptImg4} alt=""/>
					<img className="pt-img4 animate4" src={ptImg4} alt=""/>
					<img className="pt-img5 animate-wave" src={ptImg4} alt=""/>
				</div>
				
			</>
		);
	}
}

export default aboutSection;