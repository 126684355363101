import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class PerimetryDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Perimetry</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Perimetry</h2>
											<p className="nse-paragraph mb-0">Perimetry is a diagnostic procedure that we use at Nakpil Eye Clinic to assess a patient's visual field, which is the entire area that can be seen while the eyes are fixed on a central point. This test is important for detecting and monitoring various eye conditions, including glaucoma, retinal diseases, and neurological disorders, which can cause visual field defects. Perimetry helps to map and quantify these defects, providing valuable information for diagnosis and management.</p>
											<h4 className="title mb-10 mt-20">Here's an overview of perimetry:</h4>
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Types of Perimetry :</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Static Perimetry: This is the most common type of perimetry. It measures the sensitivity of different points in the visual field while the patient fixates on a central target. The most commonly used device for static perimetry is the automated perimeter, such as the Humphrey Field Analyzer (HFA). It presents small, controlled stimuli (usually light spots) at various locations within the visual field.</li>
													<li className='nse-list-group-item'>Kinetic Perimetry: In kinetic perimetry, a moving stimulus, often a lighted target on a background screen, is presented to the patient's visual field. The test technician manually moves the stimulus from the periphery toward the central visual field until the patient detects it. Kinetic perimetry is less commonly used today, as automated perimetry offers more precise and efficient testing.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Procedure :</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Patient Positioning: The patient is seated in a dimly lit room and is typically asked to focus on a central target, such as a small fixation light.</li>
													<li className='nse-list-group-item'>Test Initiation: For automated static perimetry, the patient responds when they see a stimulus, typically by pressing a button. The perimeter systematically tests multiple locations across the visual field.</li>
													<li className='nse-list-group-item'>Results Recording: The test results are plotted on a visual field chart, also known as a visual field plot. This chart displays the sensitivity of each tested location in the visual field.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">Applications :</h4>
											<p className="nse-paragraph mb-0 mt-30 text-bold ml-5 mb-2">Perimetry is used in various clinical scenarios, including : </p>
											<div className="ml-30">												
												<ul>
													<li className='nse-list-group-item'>Glaucoma Diagnosis and Management: Detecting and monitoring visual field loss is essential for diagnosing and managing glaucoma, a condition that damages the optic nerve and causes characteristic patterns of visual field defects.</li>
													<li className='nse-list-group-item'>Retinal Disease Assessment: Perimetry helps in the evaluation of conditions such as retinitis pigmentosa, macular degeneration, and diabetic retinopathy by mapping any associated visual field abnormalities.</li>
													<li className='nse-list-group-item'>Neurological Disorders: Perimetry is also used in diagnosing neurological conditions like strokes, brain tumors, and optic nerve disorders, which can cause specific visual field defects.</li>
													<li className='nse-list-group-item'>Neuro-ophthalmology: Visual field testing is used in the assessment of optic neuritis, multiple sclerosis, and other disorders affecting the visual pathways.</li>
												</ul>
											</div>
											

											<p className="nse-paragraph mb-0 mt-30">Perimetry provides important information about a patient's visual field function, which aids in early detection, diagnosis, and ongoing monitoring of various eye and neurological conditions. The results help ophthalmologists and other healthcare providers make treatment decisions, assess disease progression, and determine the impact of therapy on a patient's vision.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default PerimetryDetail;