import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class RefractionDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Refraction</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Refraction</h2>
											<p className="nse-paragraph mb-0">Refraction, is the visual examination process of determining a person's prescription for corrective lenses (glasses or contact lenses). At Nakpil Eye Clinic we use the most advance equipments and instruments that will help our doctors determine the most accurate refractive correction. Refraction can be done through various methods. The choice of method may depend on the patient's age, ability to provide feedback, and the equipment available.</p>											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Subjective Refraction</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>This is the most common and accurate method for adults and cooperative children.</li>
													<li className='nse-list-group-item'>The patient looks through a phoropter, which contains a variety of lenses, and provides feedback to the optometrist or ophthalmologist regarding the clarity of the image.</li>
													<li className='nse-list-group-item'>The practitioner fine-tunes the prescription based on the patient's responses to determine the most comfortable and clearest vision.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Retinoscopy (Objective Refraction)</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>This is an objective method used to estimate a patient's refractive error, especially in cases where the patient cannot provide subjective feedback (e.g., young children, individuals with disabilities).</li>
													<li className='nse-list-group-item'>The practitioner uses a retinoscope to shine light into the patient's eye and observes the reflection to determine the refractive error.</li>
													<li className='nse-list-group-item'>While this method can provide an initial estimate, subjective refraction is typically needed to finalize the prescription.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">Autorefractors and Aberrometers</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>These automated instruments measure the eye's refractive error without the need for subjective feedback.</li>
													<li className='nse-list-group-item'>Autorefractors provide an initial estimate of refractive error, which can then be refined through subjective refraction.</li>
													<li className='nse-list-group-item'>Aberrometers measure higher-order optical aberrations in addition to simple refractive errors.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Cycloplegic Refraction</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>In some cases, especially when dealing with children, the practitioner may use cycloplegic eye drops to temporarily paralyze the eye's focusing muscles</li>
													<li className='nse-list-group-item'>This helps in determining the most accurate prescription by preventing the eye's accommodative response.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">Manifest Refraction</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Manifest refraction is the prescription determined when the patient is not using cycloplegic eye drops.</li>
													<li className='nse-list-group-item'>It represents the patient's natural, unaided refractive state and is most commonly used for adult patients.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">Wet Refraction</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Occasionally, practitioners may use a "wet refraction" approach. The patient's eye is anesthetized, and a contact lens is placed on the eye to simulate the effects of contact lenses, especially for patients who are considering contact lens wear.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">Portable Refractometers</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>In some community health and vision screening settings, portable refractometers may be used to quickly estimate refractive errors. While not as accurate as a full eye exam, they can help identify individuals who may need a more comprehensive assessment</li>
												</ul>
											</div>

											<p className="nse-paragraph mb-0 mt-30">The choice of refraction method depends on factors like the patient's age, ability to provide feedback, and the purpose of the examination. Subjective refraction remains the gold standard for adults and cooperative children, as it allows for the most accurate and personalized prescription. However, objective methods like retinoscopy, autorefractors, and aberrometers are valuable tools for initial assessments, particularly in cases where the patient cannot provide subjective responses.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li ><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default RefractionDetail;