import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class CornealTopographyDetails extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Corneal Topography</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Corneal Topography</h2>
											<p className="nse-paragraph mb-0">Corneal topography, also known as videokeratography, is a non-invasive diagnostic technique that we use at Nakpil Eye Clinic to map 
												and analyze the curvature and shape of the cornea, which is the clear, front surface of the eye. This technology provides a detailed, 
												three-dimensional representation of the corneal surface, aiding in the diagnosis and management of various eye conditions, particularly 
												those related to refractive errors and corneal irregularities..</p>
											<div className='heading-bx mt-5 mb-5'>
												<h6 className="title-ext text-primary">Overview of corneal topography</h6>
											</div>
											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Principle of Corneal Topography</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Corneal topography is based on the projection of a pattern of illuminated rings or placido disks onto the cornea.</li>
													<li className='nse-list-group-item'>A camera or sensor captures the reflection of this pattern on the corneal surface.</li>
													<li className='nse-list-group-item'>By analyzing the distortion of the reflected pattern, a computer generates a color-coded map that represents the curvature of the cornea.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">Components of a Corneal Topographer</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Light Source: A light source, often in the form of rings or placido disks, projects onto the cornea.</li>
													<li className='nse-list-group-item'>Camera or Sensor: A camera or sensor captures the reflection of the projected pattern on the cornea.</li>
													<li className='nse-list-group-item'>Computer Software: Advanced computer software processes the captured images and creates a detailed corneal topography map.</li>
												</ul>
											</div>
											<h4 className="title mb-10 mt-10">Key Features of Corneal Topography</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>levation Map: This map displays the height and curvature of the cornea, helping to identify irregularities or changes in corneal shape.</li>
													<li className='nse-list-group-item'>Curvature Map: This map shows the corneal curvature in diopters and is helpful for diagnosing astigmatism.</li>
													<li className='nse-list-group-item'>Power Map: The power map shows refractive power across the cornea and can help identify areas with excessive or insufficient focusing power.</li>
													<li className='nse-list-group-item'>Corneal Astigmatism: Corneal topography is especially valuable in assessing and quantifying corneal astigmatism, which can influence refractive errors and vision quality.</li>
												</ul>
											</div>

											<h4 className="title mb-10 mt-10">Applications of Corneal Topography</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Refractive Surgery: Corneal topography is a crucial tool in pre-operative planning for procedures like LASIK, PRK, and implantable collamer lenses. It helps determine the appropriate treatment parameters and assess corneal stability.</li>
													<li className='nse-list-group-item'>Keratoconus Diagnosis: Keratoconus is a progressive corneal disorder characterized by thinning and steepening of the cornea. Corneal topography is essential in diagnosing and monitoring the condition.</li>
													<li className='nse-list-group-item'>Contact Lens Fitting: Corneal topography can aid in the fitting of specialized contact lenses, such as scleral lenses, for patients with irregular corneas.</li>
													<li className='nse-list-group-item'>Orthokeratology: Corneal topography is used to assess changes in corneal shape induced by orthokeratology (Ortho-K) lenses, which temporarily reshape the cornea to correct myopia.</li>
													<li className='nse-list-group-item'>Corneal Disease Management: It helps monitor and manage various corneal diseases, including dystrophies and degenerations.</li>
												</ul>
											</div>
											<p className="nse-paragraph mb-0 mt-30">Corneal topography provides valuable information to eye care professionals for the diagnosis and management of conditions affecting the cornea. It is an essential tool in optimizing visual outcomes, particularly in refractive surgery and the fitting of specialized contact lenses. The detailed corneal maps it produces aid in personalized treatment planning and monitoring of corneal health over time.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default CornealTopographyDetails;