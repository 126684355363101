import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class OcularBiometryDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Ocular Biometry</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Ocular Biometry</h2>
											<p className="nse-paragraph mb-0">Ocular Biometry, refers to the measurement of the various structural dimensions of the eye. These measurements provide critical information for the diagnosis and management of eye conditions, as well as for determining the appropriate power of intra-ocular lenses (IOLs) for cataract surgery. Accurate eye biometry is essential for achieving the best possible visual outcomes. Ocular biometry is also essential in the proper management of myopia.</p>
											<h4 className="title mb-10 mt-20">Key structural measurements in eye biometry include:</h4>
										</div>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Axial Length (AL)</h4>
											<p className="nse-paragraph mb-0">This is the measurement of the distance from the front surface of the cornea to the retina at the back of the eye. Axial length is crucial for determining the appropriate power of IOLs in cataract surgery and is also used to diagnose and monitor conditions such as myopia (nearsightedness) and hyperopia (farsightedness).</p>
											
											<h4 className="title mb-10 mt-10">Corneal Curvature</h4>
											<p className="nse-paragraph mb-0">Measuring the curvature of the cornea helps in the diagnosis of astigmatism and is important for determining the power and type of corrective lenses needed for vision correction.</p>

											<h4 className="title mb-10 mt-10">Anterior Chamber Depth (ACD)</h4>
											<p className="nse-paragraph mb-0">The distance from the back of the cornea to the front of the crystalline lens. This measurement is valuable for assessing the risk of angle-closure glaucoma and for selecting the appropriate IOL.</p>

											<h4 className="title mb-10 mt-10">Lens Thickness</h4>
											<p className="nse-paragraph mb-0">The thickness of the eye's crystalline lens, which can change with age and may be associated with certain conditions like presbyopia.</p>

											<h4 className="title mb-10 mt-10">Retinal Thickness</h4>
											<p className="nse-paragraph mb-0">Measuring the thickness of the retina is essential for diagnosing and monitoring conditions such as macular degeneration, diabetic retinopathy, and macular edema.</p>

											<h4 className="title mb-10 mt-10">Iris Thickness</h4>
											<p className="nse-paragraph mb-0">Some eye conditions, such as narrow-angle glaucoma, may be assessed by measuring iris thickness.</p>

											<p className="nse-paragraph mb-0 mt-30">Ocular biometry is typically performed using specialized equipment, such as A-scan and B-scan ultrasound, optical coherence tomography (OCT), or partial coherence interferometry (PCI). These instruments allow for accurate and non-invasive measurement of the eye's internal structures. Ocular biometry is integral to cataract surgery, refractive surgeries, and the diagnosis and management of various eye conditions. Accurate measurements ensure that intraocular lenses are chosen to provide optimal post-surgical visual outcomes. Additionally, ocular biometry aids in diagnosing and monitoring ocular diseases, facilitating timely intervention and treatment when necessary.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default OcularBiometryDetail;