import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import aboutThumb1 from '../../images/about/pic-1.png';
import aboutThumb2 from '../../images/about/pic-2.png';
import aboutThumb3 from '../../images/about/pic-3.png';
import ptImg1 from '../../images/shap/wave-orange.png';
import ptImg2 from '../../images/shap/001-eye-dropper.png';
import ptImg4 from '../../images/shap/000-Blank.png';
import ptImg5 from '../../images/shap/011-eye-exam.png';
import ptImg6 from '../../images/shap/002-eye-exam.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				<section className="section-sp1 about-area">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30">
								<div className="about-thumb-area">
									<ul>
										<li><img className="about-thumb1" src={aboutThumb1} alt=""/></li>
										<li><img className="about-thumb2" src={aboutThumb2} alt=""/></li>
										<li><img className="about-thumb3" src={aboutThumb3} alt=""/></li>
										<li><div className="exp-bx">30<span>Years of Experience</span></div></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-6 mb-30">
								<div className="heading-bx">
									<h6 className="title-ext text-primary">About Us</h6>
									<h3 className="title mb-5">We’re best known for our Excellent Eye Care Services & Vision Therapy</h3>

									<h6 className="title-ext text-primary">Our Mission</h6>
									<p>To Preserve, Rehabilitate, and Enhance human vision to Promote the increase of knowledge and optimum life activities</p>
									<h6 className="title-ext text-primary">Our Vission</h6>
									<p>To be the most Informative, Impactful, Effective and Efficient vision care provider in the Philippines.</p>
								</div>
								<div className="row">
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<Link to="/biomicroscopy-tests">
											<div className="feature-container feature-bx1 feature1">
												<div className="icon-content">
												<h4 className="ttr-title">Eye Tests</h4>
												</div>
											</div>
										</Link>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<Link to="/services">
											<div className="feature-container feature-bx1 feature2">
												<div className="icon-content">
													<h4 className="ttr-title">Eye Exam Packages</h4>
												</div>
											</div>
										</Link>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<Link to="/accommodativevergencedysfunction">
											<div className="feature-container feature-bx1 feature3">
												<div className="icon-content">
													<h4 className="ttr-title">Common Eye & Vision Conditions</h4>
												</div>
											</div>
										</Link>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<Link to="/about-us">
											<div className="feature-container feature-bx1 feature4">
												<div className="icon-content">
													<h4 className="ttr-title">Vision Therapy / Neuro Optometric Rehabilitation</h4>
												</div>
											</div>
										</Link>
									</div>
								</div>
								<Link to="/about-us" className="btn btn-primary shadow">Read More</Link>
							</div>
						</div>
					</div>
					<img className="pt-img1 animate-wave" src={ptImg4} alt=""/>
					<img className="pt-img2 animate2" src={ptImg4} alt=""/>
					<img className="pt-img3 animate-rotate" src={ptImg4} alt=""/>
					<img className="pt-img4 animate-wave" src={ptImg4} alt=""/>
					<img className="pt-img5 animate2" src={ptImg4} alt=""/>
				</section>
				
			</>
		);
	}
}

export default aboutSection;