import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// Layout
import Header from "./layout/header";
import Footer from "./layout/footer";

// All Pages Router
import Index from './pages/index';
import AboutUs from './pages/about-us';
import Team from './pages/team';
import Services from './pages/services';
import ServiceDetail from './pages/service-detail';
import FormLogin from './pages/form-login';
import FormRegister from './pages/form-register';
import FormForgetPassword from './pages/form-forget-password';
import Faq from './pages/faq';
import ContactUs from './pages/contact-us';
import Booking from './pages/booking';
import BlogGrid from './pages/blog-grid';
import BlogDetails from './pages/blog-details';
import Error from './pages/error-404';

//Tests
import BioMicroscopyDetail from './pages/tests/biomicroscopy';
import CornealTopographyDetails from './pages/tests/cornealtopography';
import DryEyeDetails from './pages/tests/dryeyetest';
import FundoscopyDetail from './pages/tests/fundoscopy';
import KeratometryDetail from './pages/tests/keratometry';
import MeibomianGlandAssesmentDetail from './pages/tests/meibomianglandassesment';
import OcularBiometryDetail from './pages/tests/ocularbiometry';
import OpticalCoherenceTomographyDetail from './pages/tests/opticalcoherencetomography';
import PachymetryDetail from './pages/tests/pachymetry';
import PerimetryDetail from './pages/tests/perimetry';
import RefractionDetail from './pages/tests/refraction';
import SensoryMotorTestDetail from './pages/tests/sensorymotor';
import SynoptophoreDetail from './pages/tests/synoptophore';
import TonometryDetail from './pages/tests/tonometry';

//Services
import StandardEyeExam from './pages/services/standardeyeexam';
import PremiumExam from './pages/services/premiumeyeexam';
import PediatricExam from './pages/services/pediatriceyeexam';
import ExecutiveExam from './pages/services/executiveeyeexam';

//Conditions
import AccommodativeVergenceDysfunction from './pages/visionconditions/avdysfunction';
import Amblyopia from './pages/visionconditions/amblyopia';

class Markup extends Component{
	render(){
		return(
			<>	
			
				{/* {<BrowserRouter basename={'/react/'}> */}
				<BrowserRouter>
					
					<Routes>
						
						<Route element={<ThemeLayout />}>
							<Route path='/' element={<Index />} />
							<Route path='/about-us' element={<AboutUs />} />
							<Route path='/team' element={<Team />} />
							<Route path='/services' element={<Services />} />
							<Route path='/service-detail' element={<ServiceDetail />} />
							<Route path='/faq' element={<Faq />} />
							<Route path='/contact-us' element={<ContactUs />} />
							<Route path='/booking' element={<Booking />} />
							<Route path='/blog-grid' element={<BlogGrid />} />
							<Route path='/blog-details' element={<BlogDetails />} />
							{/* TESTS */}
							<Route path='/biomicroscopy-tests' element={<BioMicroscopyDetail />} />
							<Route path='/cornealtopography-tests' element={<CornealTopographyDetails />} />
							<Route path='/dryeye-tests' element={<DryEyeDetails />} />
							<Route path='/fundoscopy-tests' element={<FundoscopyDetail />} />
							<Route path='/keratometry-tests' element={<KeratometryDetail />} />
							<Route path='/meibomianglandassessment-tests' element={<MeibomianGlandAssesmentDetail />} />
							<Route path='/ocularbiometry-tests' element={<OcularBiometryDetail />} />
							<Route path='/opticalcoherencetomography-tests' element={<OpticalCoherenceTomographyDetail />} />
							<Route path='/pachymetry-tests' element={<PachymetryDetail />} />
							<Route path='/perimetry-tests' element={<PerimetryDetail />} />
							<Route path='/refraction-tests' element={<RefractionDetail />} />
							<Route path='/sensorymotor-tests' element={<SensoryMotorTestDetail />} />
							<Route path='/synoptophore-tests' element={<SynoptophoreDetail />} />
							<Route path='/tonometry-tests' element={<TonometryDetail />} />
							{/* Services */}
							<Route path='/standardeyeexam' element={<StandardEyeExam />} />
							<Route path='/premiumeyeexam' element={<PremiumExam />} />
							<Route path='/pediatriceyeexam' element={<PediatricExam />} />
							<Route path='/executiveeyeexam' element={<ExecutiveExam />} />

							{/* Conditions */}
							<Route path='/accommodativevergencedysfunction' element={<AccommodativeVergenceDysfunction />} />
							<Route path='/amblyopia' element={<Amblyopia />} />

							<Route path="*" element={<Error />} />
						</Route>
						
						<Route path="/form-login" element={<FormLogin />} />
						<Route path="/form-register" element={<FormRegister />} />
						<Route path='/form-forget-password' element={<FormForgetPassword />} />
						
					</Routes>
					
					<PageScrollTop />
					
				</BrowserRouter>
				
				<BackToTop />
				
			</>
		);
	}
}
function ThemeLayout(){
	return(
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}
export default Markup;