import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import Downloadables from '../../elements/downloadables';

class TonometryDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Tonometry</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Tonometry</h2>
											<p className="nse-paragraph mb-0">Tonometry is a diagnostic procedure that we use at Nakpil Eye Clinic to measure the intraocular pressure (IOP) inside the eye. This measurement is crucial in the evaluation and management of various eye conditions, particularly glaucoma, as elevated intraocular pressure is a significant risk factor for optic nerve damage and vision loss. There are several methods for performing tonometry, and each has its own advantages and indications.</p>											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Applanation Tonometry</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Goldmann Applanation Tonometry: This is considered the gold standard for IOP measurement. It involves using a special instrument called a Goldmann tonometer, which consists of a prism that gently flattens a small area of the cornea. The force required to flatten the cornea is directly related to the intraocular pressure.</li>
													<li className='nse-list-group-item'>Pros: Accurate and widely accepted as the reference standard. It is often used in clinical settings and for glaucoma diagnosis and management.</li>
													<li className='nse-list-group-item'>Cons: Requires skill and experience to use effectively. Direct contact with the eye may cause mild discomfort and a risk of corneal injury.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Non-contact (Air-Puff) Tonometry</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Non-contact tonometry, often referred to as the "air-puff" test, uses a quick burst of air to flatten a portion of the cornea. The instrument measures the time it takes for the cornea to return to its normal shape after the air puff.</li>
													<li className='nse-list-group-item'>Pros: Non-invasive, no direct contact with the eye, and provides a reasonable estimate of IOP.</li>
													<li className='nse-list-group-item'>Cons: Less accurate than Goldmann applanation tonometry. It is often used for initial IOP screenings but may not replace applanation tonometry for definitive measurements.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Tono-Pen</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>The Tono-Pen is a handheld, portable tonometer that directly contacts the cornea to measure IOP. It is often used in situations where portability and ease of use are essential.</li>
													<li className='nse-list-group-item'>Pros: Portable, easy to use, and provides reasonably accurate IOP measurements.</li>
													<li className='nse-list-group-item'>Cons: Requires direct contact with the eye and may require calibration.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Rebound Tonometry</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Rebound tonometry, such as the iCare tonometer, measures IOP by directing a small probe onto the cornea. It works on the principle that the probe's rebound from the eye is related to IOP</li>
													<li className='nse-list-group-item'>Pros: Non-invasive, no need for anesthesia, and easy to use.</li>
													<li className='nse-list-group-item'>Cons: Somewhat less accurate than applanation tonometry, and measurements can be influenced by factors like corneal thickness.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Ocular Response Analyzer (ORA)</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>The ORA is a more advanced tonometry system that measures IOP and corneal biomechanical properties. It uses a small, gentle air puff to deform the cornea and assess its response.</li>
													<li className='nse-list-group-item'>Pros: Provides additional information about corneal hysteresis, which can be valuable in glaucoma management.</li>
													<li className='nse-list-group-item'>Cons: Complex, less common, and more expensive.</li>
												</ul>
											</div>

											<p className="nse-paragraph mb-0 mt-30">The choice of tonometry method depends on the specific needs of the patient and the clinical context. Ophthalmologists and optometrists use these methods to assess IOP, screen for glaucoma, monitor disease progression, and make treatment decisions. It's important to have regular eye exams and tonometry measurements, especially if you have risk factors for glaucoma or other eye conditions that affect intraocular pressure.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li ><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default TonometryDetail;