import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class OpticalCoherenceTomographyDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Optical Coherence Tomography</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Optical Coherence Tomography (OCT)</h2>
											<p className="nse-paragraph mb-0">Optical Coherence Tomography (OCT) is a non-invasive imaging technology that we use at Nakpil Eye Clinic to visualize and assess the structures of the eye specially the layers of the retina. OCT is similar in principle to ultrasound, but it uses light waves instead of sound waves to create detailed, cross-sectional images of biological tissues.</p>
											<h4 className="title mb-10 mt-20">Principle of Operation :</h4>
										</div>
										<div className="row align-items-center">
											<div className="col-md-12 mb-30">
												<ul className="mb-0">
													<li>1.) OCT is based on low-coherence interferometry. It measures the echoes of backscattered or reflected light to create detailed cross-sectional images of tissues.</li>
													<li>2.) A light source emits a beam of near-infrared light, which is directed into the eye or the area being examined.</li>
													<li>3.) The light is split into two arms: a reference arm and a sample arm.</li>
													<li>4.) In the sample arm, the light is directed onto the tissue of interest, and the backscattered or reflected light is collected.</li>
													<li>5.) Light from the reference arm is combined with the sample arm light, and an interferogram is created. The resulting interference pattern provides depth information about the tissues being examined.</li>
													<li>6.) The interference pattern is analyzed to produce a high-resolution, cross-sectional image of the tissue's internal structures.</li>
												</ul>
											</div>											
										</div>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Components of an OCT System :</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Light source: A superluminescent diode or a swept-source laser emitting near-infrared light.</li>
													<li className='nse-list-group-item'>Interferometer: A device that combines the reference and sample arm light and generates the interference pattern.</li>
													<li className='nse-list-group-item'>Scanner: A scanning mechanism that moves the beam of light to create cross-sectional images. In ophthalmic OCT, this may be a scanning laser or a rotating mirror.</li>
													<li className='nse-list-group-item'>Detector: A high-sensitivity photodetector that measures the interference pattern.</li>
													<li className='nse-list-group-item'>Computer and software: The system processes the data and generates images in real-time, allowing for immediate analysis and diagnosis.</li>
												</ul>
											</div>
											
											<h4 className="title mb-10 mt-10">Applications of OCT :</h4>
											<p className="nse-paragraph mb-0">OCT is widely used in ophthalmology to visualize the structures of the eye, including the cornea, retina, optic nerve, and anterior chamber. It is essential for diagnosing and managing various eye conditions, such as macular degeneration, glaucoma, diabetic retinopathy, and retinal vascular diseases. In addition to ophthalmology, OCT has applications in cardiology, dermatology, and various medical specialties for imaging different tissues and structures.</p>


											<h4 className="title mb-10 mt-10">Advantages of OCT :</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>High Resolution: OCT provides detailed, high-resolution cross-sectional images of tissues.</li>
													<li className='nse-list-group-item'>Non-Invasive: It does not require physical contact or invasive procedures.</li>
													<li className='nse-list-group-item'>Real-Time Imaging: OCT generates images in real-time, allowing immediate analysis and diagnosis.</li>
													<li className='nse-list-group-item'>Depth Information: OCT provides depth information about the structures being imaged.</li>
												</ul>
											</div>

											<p className="nse-paragraph mb-0 mt-30">OCT has revolutionized the field of ophthalmology by enabling earlier and more accurate diagnoses and facilitating the monitoring of treatment outcomes. It is an invaluable tool for clinicians in multiple medical disciplines, providing non-invasive and high-quality imaging for various diagnostic and research purposes.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default OpticalCoherenceTomographyDetail;