import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class KeratometryDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Keratometry</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Keratometry</h2>
											<p className="nse-paragraph mb-0">Keratometry is a diagnostic procedure that we use at Nakpil Eye Clinic to measure the curvature of the cornea, which is the clear front surface of the eye. The primary purpose of keratometry is to determine the corneal curvature, particularly its meridional or astigmatic values. This information is crucial for the assessment of refractive errors, the fitting of contact lenses, and the pre-operative planning for certain eye surgeries, such as cataract surgery or refractive surgery like LASIK.</p>
											<h4 className="title mb-10 mt-20">Keratometry is valuable for several purposes:</h4>
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Refraction Assessment</h4>
											<p className="nse-paragraph mb-0">Corneal curvature measurements are essential in calculating the initial power of contact lenses or intraocular lenses (IOLs) for patients undergoing cataract surgery.</p>
											
											<h4 className="title mb-10 mt-10">Astigmatism Diagnosis</h4>
											<p className="nse-paragraph mb-0">Keratometry helps diagnose and quantify corneal astigmatism, which can be present with myopia (nearsightedness) or hyperopia (farsightedness). Astigmatism correction is vital for clear vision.</p>

											<h4 className="title mb-10 mt-10">Orthokeratology and Contact Lens Fitting</h4>
											<p className="nse-paragraph mb-0">For fitting contact lenses, accurate corneal curvature measurements help ensure a proper fit and optimal vision correction.</p>

											<h4 className="title mb-10 mt-10">Laser Vision Correction</h4>
											<p className="nse-paragraph mb-0">In procedures like LASIK, keratometry data is used to plan the laser ablation, reshaping the cornea to correct refractive errors.</p>
											

											<p className="nse-paragraph mb-0 mt-30">Keratometry is a non-invasive and relatively quick procedure that provides essential information for eye care professionals to prescribe appropriate vision correction measures and surgical interventions. Accurate measurements are crucial for achieving the best possible visual outcomes and maintaining eye health.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default KeratometryDetail;