import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import aboutThumb1 from '../../images/about/pic-1.png';
import aboutThumb2 from '../../images/about/pic-2.png';
import aboutThumb3 from '../../images/about/pic-3.png';
import ptImg1 from '../../images/shap/wave-orange.png';
import ptImg2 from '../../images/shap/001-eye-dropper.png';
import ptImg4 from '../../images/shap/000-Blank.png';
import ptImg5 from '../../images/shap/011-eye-exam.png';
import ptImg6 from '../../images/shap/002-eye-exam.png';

class CompanySection extends Component{
	render(){
		return(
			<>
				<section className="section-sp1 about-area">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-12 mb-30">
								<div className="heading-bx">
									<h6 className="title-ext text-primary">Company Profile</h6>
									<h2 className="title">Nakpil Eye Clinic and Vision Therapy Center</h2>
									<p className="nse-paragraph">Nakpil Eye Clinic and Vision Therapy Center is a leading provider of eye and 
										vision care services. We are a team of Optometrist and Ophthalmologist committed to providing 
										premium quality products and services, as well as the best customer service possible.
										Company Mission Statement: Our mission is to preserve, rehabilitate and enhance human vision to 
										promote the increase of knowledge & optimum life activities. (knowing that vision plays a vital role in 
										learning and performing various life activities).</p>
									<h6 className="title-ext text-primary">History</h6>
									<p className="nse-paragraph">Dr. John U. Nakpil, the founder of Nakpil Eye Clinic, began his clinical practice in 
										March 1992 in Tanza, Cavite, before relocating to Dasmariñas, Cavite, in 1997. Dr. Nakpil has always 
										been passionate about helping patients with special visual conditions such as amblyopia (lazy eye), 
										strabismus(cross eye), diplopia (double vision) caused by traumatic brain injuries, and other vision-related learning disabilities. His specialized clinical practice gained recognition in the eye and vision 
										care industry, with a significant number of patients referred to him by optometrists, ophthalmologists, 
										and other healthcare professionals.</p>
									<p className="nse-paragraph">In December 2020, during the height of the pandemic, Dr. Nakpil decided to expand his specialty 
										clinical practice to better serve the rapidly growing number of patients in need of special vision care. 
										He assembled a team of optometrists and ophthalmologists to train them in his unique approach to 
										eye and vision care. His vision is to establish a company that aims to be the most informative, 
										impactful, effective, and efficient vision care provider in the Philippines. Since our inception, we have 
										grown to become one of the leading providers of vision care in the industry.</p>
									<h6 className="title-ext text-primary">Company Products & Services</h6>
									<p className="nse-paragraph">We offer a wide range of comprehensive eye examination packages, 
										vision therapy treatments and neuro optometric rehabilitation. Our services are designed to meet the 
										needs of patients of all ages.</p>
								</div>								
							</div>
						</div>
					</div>
					<img className="pt-img1 animate-wave" src={ptImg4} alt=""/>
					<img className="pt-img2 animate2" src={ptImg4} alt=""/>
					<img className="pt-img3 animate-rotate" src={ptImg4} alt=""/>
					<img className="pt-img4 animate-wave" src={ptImg4} alt=""/>
					<img className="pt-img5 animate2" src={ptImg4} alt=""/>
				</section>
				
			</>
		);
	}
}

export default CompanySection;