import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class DryEyeDetails extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Dry Eye Test</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Dry Eye Test</h2>
											<p className="nse-paragraph mb-0">Assessing the lipid layer of the tear film is an essential part of diagnosing and managing dry eye syndrome, also known as keratoconjunctivitis sicca. The lipid layer is one of three key components of the tear film, along with the aqueous (watery) layer and the mucin layer. The lipid layer serves to reduce tear evaporation and maintain a stable tear film on the ocular surface.</p>
											<h4 className="title mb-10 mt-20">How the assessment of the lipid layer in dry eye is typically conducted</h4>
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Symptoms and History</h4>
											<p className="nse-paragraph mb-0">The assessment often begins with a discussion of the patient's symptoms and medical history. Common symptoms of dry eye include eye discomfort, burning or stinging sensations, excessive tearing (paradoxically, due to reflex tearing), and fluctuating or blurry vision. Information about the patient's occupation, medication use, and environmental factors (e.g., exposure to dry or windy conditions) is also gathered.</p>
											
											<h4 className="title mb-10 mt-10">Ocular Surface Examination</h4>
											<p className="nse-paragraph mb-0">A comprehensive ocular surface examination is performed using various ophthalmic instruments, including a slit lamp biomicroscope. The examination aims to evaluate the overall health of the ocular surface and assess for signs of dry eye, such as conjunctival redness, corneal staining, and meibomian gland dysfunction. The meibomian glands are responsible for producing the lipid layer.</p>

											<h4 className="title mb-10 mt-10">Meibomian Gland Evaluation</h4>
											<p className="nse-paragraph mb-0">Dysfunction of the meibomian glands is a common cause of evaporative dry eye. These glands secrete lipids that form the outermost layer of the tear film. Meibomian gland function is assessed by gently pressing on the eyelids to express the gland's secretions. The quality, quantity, and expressibility of these secretions are examined. Abnormal secretions can be thick, cloudy, or absent.</p>

											<h4 className="title mb-10 mt-10">Tear Film Break-Up Time (TBUT)</h4>
											<p className="nse-paragraph mb-0">TBUT is a test that evaluates the stability of the tear film, including the lipid layer. A small amount of fluorescein dye is instilled onto the ocular surface, and the time it takes for the first dry spot or break in the tear film to appear is measured. A shorter TBUT is indicative of tear film instability, which can result from a compromised lipid laye.</p>

											<h4 className="title mb-10 mt-10">Lipid Layer Thickness Assessment</h4>
											<p className="nse-paragraph mb-0">More advanced diagnostic tools, such as interferometry or meibography, can directly assess the lipid layer. Interferometry uses the principle of interference patterns created by light reflecting off the tear film's layers, enabling measurement of the lipid layer's thickness and quality. Meibography uses infrared light to visualize the meibomian glands directly.</p>

											<h4 className="title mb-10 mt-10">Tear Osmolarity</h4>
											<p className="nse-paragraph mb-0">Tear osmolarity, a measure of the tear film's salt concentration, is higher in dry eye. Elevated osmolarity can be linked to tear film instability, which may result from a compromised lipid layer.</p>

											<h4 className="title mb-10 mt-10">Tear Composition Analysis</h4>
											<p className="nse-paragraph mb-0">In some cases, laboratory analysis of tear composition, including lipid content, may be conducted to provide a detailed assessment of the lipid layer's quality and quantity.</p>

											<p className="nse-paragraph mb-0 mt-30">Based on the results of these assessments, a diagnosis of dry eye can be made, and appropriate treatment and management strategies, such as artificial tears, warm compresses, lid hygiene, or medication, can be recommended to address the specific underlying causes and alleviate symptoms.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default DryEyeDetails;