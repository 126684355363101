import React, { Component } from 'react';
import { Link } from 'react-router-dom';


// Import Images
import bg1 from '../../images/background/line-bg1.png';
import ptImg1 from '../../images/shap/008-ishihara-test.png';
import ptImg2 from '../../images/shap/004-sunglasses.png';
import ptImg3 from '../../images/shap/000-Blank.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<section className="section-area section-sp5 work-area" style={{backgroundImage: "url("+bg1+")", backgroundRepeat:" no-repeat", backgroundPosition: "center", backgroundSize: "100%"}}>
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Lets get intouch</h6>
							<h2 className="title">Schedule an Appointment with us</h2>
						</div>
						<div className="row justify-content-center">
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">01</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Book an Appointmnet</h5>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
									</div>
									<Link to="/booking" className="btn btn-primary ">Book Now <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx active">
									<div className="work-num-bx">02</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Patient Examination</h5>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
									</div>
									<Link to="/services" className="btn btn-primary">Learn More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">03</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Physician's Diagnosis</h5>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
									</div>
									
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">04</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Follow-up Checkup</h5>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
									</div>
									
								</div>
							</div>
						</div>
					</div>
					<img className="pt-img1 animate1" src={ptImg3} alt=""/>
					<img className="pt-img2 animate2" src={ptImg3} alt=""/>
					<img className="pt-img3 animate3" src={ptImg3} alt=""/>
				</section>
				
			</>
		);
	}
}

export default aboutSection;